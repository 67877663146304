<template>
  <div>
    <div class="row">
      <div class="col-xl-3 col-md-4 col-6 my-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select v-model="vehicle.customer_reference" class="form-select">
          <option value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Marque </label>
        <input
          type="text"
          v-model="vehicle.manufacturerName"
          class="form-control"
          list="carBrandList"
        />
        <datalist id="carBrandList">
          <option
            v-for="(car, index) in carModels"
            :key="index++"
            :value="car.brand"
          >
            {{ car.brand }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Modèle </label>
        <input
          type="text"
          v-model="vehicle.modelName"
          class="form-control"
          list="carModelList"
        />
        <datalist id="carModelList">
          <option
            v-for="(model, index) in filtredCarModels"
            :key="index++"
            :value="model"
          >
            {{ model }}
          </option>
        </datalist>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Nº d’immatriculation </label>
        <input
          type="text"
          v-model="vehicle.registrationPlate"
          class="form-control"
          placeholder=""
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Kilométrage </label>
        <input
          type="text"
          pattern="[0-9]*"
          v-model="vehicle.mileage"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Mise en circulation </label>
        <input
          type="date"
          v-model="vehicle.firstRegistrationDate"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Nº de Châssis</label>
        <input
          type="text"
          v-model="vehicle.serialNumber"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Carburant </label>
        <select v-model="vehicle.fuelType" class="form-select">
          <option value="Essence">Essence</option>
          <option value="Diesel">Diesel</option>
        </select>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Boîte Vitesse</label>
         <select v-model="vehicle.gearbox" class="form-select">
          <option value="Manuelle">Manuelle</option>
          <option value="Automatique ">Automatique </option>
        </select>
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Moteur </label>
        <input type="text" v-model="vehicle.engineName" class="form-control" />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Année de fabrication </label>
        <input
          type="text"
          pattern="[0-9]{4}"
          v-model="vehicle.yearManufacture"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Maximum passenger </label>
        <input
          type="text"
          v-model="vehicle.seatingCapacity"
          class="form-control"
        />
      </div>

      <div class="col-xl-3 col-3 my-3">
        <label for=""> Type </label>
        <input
          type="text"
          v-model="vehicle.type"
          class="form-control"
        />
      </div>
    </div>

    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(vehicle)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import carModels from "../../data/car-models/index.json";
export default {
  data() {
    return {
      vehicle: {},
      carModels,
    };
  },
  computed: {
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    filtredCarModels() {
      var brand = carModels.find(
        (p) => p.brand == this.vehicle.manufacturerName
      );
      if (brand != undefined) return brand.models;
      return [];
    },
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("vehicle/store", data);
    },
    async empty() {
      this.$store.commit("vehicle/setVehicle", {});
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getAll");
    this.vehicle.customer_reference = this.$route.query.customer_reference;
  },
};
</script>
